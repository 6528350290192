import React from 'react';

const IconBrand = () => (
  <svg id="Brand" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 812.39 804.03">
    <title>brand</title>
    <path d="M1082.35,789.11l-103-269.23L867.54,633.39,971.3,902.23c11,28.79,26,50.73,59,50.73h13l130.94-402.41h4.8V942h-43.87v11h223.49V942h-43.87V490.22h43.87v-11h-175.5Z" transform="translate(-537.82 -110.09)"/>
    <path d="M690.51,364.24c0-170,28.11-240.62,98.72-240.62,80.21,0,110.37,110.37,130.94,181.67h8.91V110.59h-8.91s-6.86,24-31.53,24c-37,0-50-24-115.17-24-163.85,0-235.14,134.37-235.14,248.17,0,139.18,66.65,239.83,212,250.62l17.41-17.68C711.38,575.76,690.51,493.6,690.51,364.24Z" transform="translate(-537.82 -110.09)"/>
    <path d="M821.39,819.28c0,90.49-2.74,120.66-77.47,122.71h-7.54v11H929V942H912.57c-74.72,0-77.47-24-77.47-122V668.65l-13.71,13.92Z" transform="translate(-537.82 -110.09)"/>
  </svg>
);

export default IconBrand;