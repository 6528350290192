const fontSizes = {
  xxs: '12px',
  xs: '13px',
  sm: '14px',
  md: '16px',
  lg: '18px',
  xl: '20px',
  xxl: '22px',
  heading: '32px'
}

export default fontSizes